import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setParticles, setHighlight, setPresentMode, setMusic, setNotes } from '../../../../app/features/settingsSlice';

import ToggleField from '../../../ui/form/ToggleField/ToggleField';

import styles from './Settings.module.css';

import settings from '../../../../assets/settings-icon.svg';

function Settings({ children, instantHide, ...props }) {
    const [isOpen, setIsOpen] = useState(false);
    const settingsRef = useRef(null);

    const particles = useSelector(state => state.settings.settings.particles);
    const highlight = useSelector(state => state.settings.settings.highlight);
    const present_mode = useSelector(state => state.settings.settings.present_mode);
    const notes = useSelector(state => state.settings.settings.notes);
    const music = useSelector(state => state.settings.settings.music);
    const isUserSearching = useSelector(state => state.settings.isUserSearching);
    const isMenuOpen = useSelector((state) => state.settings.isMenuOpen);
    const dispatch = useDispatch();

    const handleOpen = () => {
        setIsOpen(!isOpen);
    }

    const handleParticlesChange = (event) => {
        dispatch(setParticles(event.target.checked));
    }

    const handleHighlightChange = (event) => {
        dispatch(setHighlight(event.target.checked));
    }

    const handlePresentModeChange = (event) => {
        dispatch(setPresentMode(event.target.checked));
        instantHide(event.target.checked);
    }

    const handleMusicChange = (event) => {
        dispatch(setMusic(event.target.checked));
    }
    const handleNotesChange = (event) => {
        dispatch(setNotes(event.target.checked));
    }

    const handleClickOutside = (event) => {
        if (settingsRef.current && !settingsRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    useEffect(() => {

        const handleKeydown = (event) => {
            const key = event.keyCode;
            const isModifierKey = event.metaKey || event.ctrlKey || event.altKey || event.shiftKey;

            // Ignore common shortcuts like Cmd+C, Cmd+V, etc.
            if (isModifierKey) {
                return;
            }
            
            if (key === 65) {
                event.preventDefault();
                dispatch(setHighlight(!highlight));
            }
            if (key === 66) {
                event.preventDefault();
                dispatch(setParticles(!particles));
            }
            if (key === 78) {
                event.preventDefault();
                dispatch(setNotes(!notes));
            }
            if (key === 80) {
                event.preventDefault();
                dispatch(setPresentMode(!present_mode));
                instantHide(!present_mode);
            }
            if (key === 77) {
                event.preventDefault();
                dispatch(setMusic(!music));
            }

        };

        if (!isUserSearching && !isMenuOpen) {
            window.addEventListener('keydown', handleKeydown);
        }
    
        return () => {
          window.removeEventListener('keydown', handleKeydown);
        };
    }, [isMenuOpen, isUserSearching, dispatch, instantHide, highlight, particles, present_mode, music, notes]);

    return (
        <div className={styles.settings} ref={settingsRef} {...props}>
            <img src={settings} alt="Settings" onClick={handleOpen} />

            <div className={`${styles.settings_list} ${isOpen ? styles.active : ''}`}>
                <ToggleField 
                    label="Auto-Highlight"
                    name="settings_highlight"
                    checked={highlight}
                    onChange={handleHighlightChange}
                />
                <ToggleField 
                    label="Notes"
                    name="settings_notes"
                    checked={notes}
                    onChange={handleNotesChange}
                />
                <ToggleField 
                    label="Particles"
                    name="settings_particles"
                    checked={particles}
                    onChange={handleParticlesChange}
                />
                <ToggleField 
                    label="Present Mode"
                    name="settings_present_mode"
                    checked={present_mode}
                    onChange={handlePresentModeChange}
                />
                <ToggleField 
                    label="Music"
                    name="settings_music"
                    checked={music}
                    onChange={handleMusicChange}
                />
            </div>
        </div>
    );
}
    
export default Settings;