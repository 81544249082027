import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setItemWithExpiration, getItemWithExpiration } from '../../utils/storage';
import axiosInstance from '../../services/utils/axiosInstance';

export const fetchCategories = createAsyncThunk(
  'settings/fetchCategories',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get('/category/list/');
      return response.data;
    }
    catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchBooks = createAsyncThunk(
  'settings/fetchBooks',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get('/book/list/');
      return response.data;
    }
    catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCategory = createAsyncThunk(
  'settings/getCategory',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/category/get/${id}`);
      return response.data;
    }
    catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  viewMode: getItemWithExpiration('viewMode') || 'canon',
  settings: {
    particles: getItemWithExpiration('particles') ? true : false,
    notes: getItemWithExpiration('notes') ? true : false,
    highlight: getItemWithExpiration('highlight') ? true : false,
    present_mode: getItemWithExpiration('present_mode') ? true : false,
    music: getItemWithExpiration('music') ? true : false,
    repeat: getItemWithExpiration('repeat') ? true : false,
    crossPopupSize: getItemWithExpiration('crossPopupSize') ? getItemWithExpiration('crossPopupSize') : 1,
  },
  isCrossPopupOpen: false,
  isMobile: window.innerWidth < 768 ? true : false,
  categories: [
    // {
    //   'id': 1,
    //   'name': 'Verses omitted',
    //   'slug': 'verses-omitted',
    //   'verses_total': 17,
    // }
  ],
  canon_list: [
    // {
    //   'id': 1,
    //   'name': 'Genesis',
    //   'slug': 'genesis',
    //   'order': 4,
    //   'chapters': [
    //     {
    //       'id': 1,
    //       'number': 1,
    //     },
    //     {
    //       'id': 3,
    //       'number': 3,
    //     },
    //     {
    //       'id': 4,
    //       'number': 4,
    //     }
    //   ]
    // }
  ],
  activeVerse: {
    title: '',
    verse_number: null,
    verse_size_1: 100,
    verse_size_2: 100,
    verse_csb: '',
    verse_esv: '',
    verse_kjb: '',
    verse_lsb: '',
    verse_nasb: '',
    verse_niv: '',
    verse_nkjv: '',
    verse_nlt: '',
    verse_amp: '',
    verse_hcsb: '',
    verse_gnt: '',
    verse_ceb: '',
    verse_rsv: '',
    verse_nrsv: '',
    verse_net: '',
    verse_msg: '',
    verse_mt: '',
    verse_tr: '',
    description_csb: '',
    description_esv: '',
    description_kjb: '',
    description_lsb: '',
    description_nasb: '',
    description_niv: '',
    description_nkjv: '',
    description_nlt: '',
    description_amp: '',
    description_hcsb: '',
    description_gnt: '',
    description_ceb: '',
    description_rsv: '',
    description_nrsv: '',
    description_net: '',
    description_msg: '',
    description_mt: '',
    description_tr: '',
    categories: [],
    books: [],
    chapters: [],
    lost_cross_list: []
  },
  activeVerseVersion: null,
  activeCategory: null,
  isUserSearching: false,
  isMenuOpen: false,
};

if (getItemWithExpiration('particles') === null) {
  initialState.settings.particles = true;
}
if (getItemWithExpiration('highlight') === null) {
  initialState.settings.highlight = true;
}
if (getItemWithExpiration('notes') === null) {
  initialState.settings.notes = true;
}
if (getItemWithExpiration('activeVerseVersion') === null) {
  initialState.activeVerseVersion = getItemWithExpiration('activeVerseVersion');
}
if (getItemWithExpiration('crossPopupSize') === null) {
  initialState.settings.crossPopupSize = 1;
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setActiveVerse: (state, action) => {
      state.activeVerse = action.payload;

      if (action.payload.verse_niv) {
        state.activeVerseVersion = 'niv';
      }
      else if (action.payload.verse_esv) {
        state.activeVerseVersion = 'esv';
      }
      else if (action.payload.verse_nasb) {
        state.activeVerseVersion = 'nasb';
      }
      else if (action.payload.verse_nkjv) {
        state.activeVerseVersion = 'nkjv';
      }
      else if (action.payload.verse_lsb) {
        state.activeVerseVersion = 'lsb';
      }
      else if (action.payload.verse_nlt) {
        state.activeVerseVersion = 'nlt';
      }
      else if (action.payload.verse_csb) {
        state.activeVerseVersion = 'csb';
      }
      else if (action.payload.verse_amp) {
        state.activeVerseVersion = 'amp';
      }
      else if (action.payload.verse_hcsb) {
        state.activeVerseVersion = 'hcsb';
      }
      else if (action.payload.verse_gnt) {
        state.activeVerseVersion = 'gnt';
      }
      else if (action.payload.verse_ceb) {
        state.activeVerseVersion = 'ceb';
      }
      else if (action.payload.verse_rsv) {
        state.activeVerseVersion = 'rsv';
      }
      else if (action.payload.verse_nrsv) {
        state.activeVerseVersion = 'nrsv';
      }
      else if (action.payload.verse_net) {
        state.activeVerseVersion = 'net';
      }
      else if (action.payload.verse_msg) {
        state.activeVerseVersion = 'msg';
      }
      else if (action.payload.verse_mt) {
        state.activeVerseVersion = 'mt';
      }
      else if (action.payload.verse_tr) {
        state.activeVerseVersion = 'tr';
      }

      if (getItemWithExpiration('activeVerseVersion')) {

        if (getItemWithExpiration('activeVerseVersion') === 'niv' && action.payload.verse_niv) {
          state.activeVerseVersion = 'niv';
        }
        else if (getItemWithExpiration('activeVerseVersion') === 'esv' && action.payload.verse_esv) {
          state.activeVerseVersion = 'esv';
        }
        else if (getItemWithExpiration('activeVerseVersion') === 'nasb' && action.payload.verse_nasb) {
          state.activeVerseVersion = 'nasb';
        }
        else if (getItemWithExpiration('activeVerseVersion') === 'nkjv' && action.payload.verse_nkjv) {
          state.activeVerseVersion = 'nkjv';
        }
        else if (getItemWithExpiration('activeVerseVersion') === 'lsb' && action.payload.verse_lsb) {
          state.activeVerseVersion = 'lsb';
        }
        else if (getItemWithExpiration('activeVerseVersion') === 'nlt' && action.payload.verse_nlt) {
          state.activeVerseVersion = 'nlt';
        }
        else if (getItemWithExpiration('activeVerseVersion') === 'csb' && action.payload.verse_csb) {
          state.activeVerseVersion = 'csb';
        }
        else if (getItemWithExpiration('activeVerseVersion') === 'amp' && action.payload.verse_amp) {
          state.activeVerseVersion = 'amp';
        }
        else if (getItemWithExpiration('activeVerseVersion') === 'hcsb' && action.payload.verse_hcsb) {
          state.activeVerseVersion = 'hcsb';
        }
        else if (getItemWithExpiration('activeVerseVersion') === 'gnt' && action.payload.verse_gnt) {
          state.activeVerseVersion = 'gnt';
        }
        else if (getItemWithExpiration('activeVerseVersion') === 'ceb' && action.payload.verse_ceb) {
          state.activeVerseVersion = 'ceb';
        }
        else if (getItemWithExpiration('activeVerseVersion') === 'rsv' && action.payload.verse_rsv) {
          state.activeVerseVersion = 'rsv';
        }
        else if (getItemWithExpiration('activeVerseVersion') === 'nrsv' && action.payload.verse_nrsv) {
          state.activeVerseVersion = 'nrsv';
        }
        else if (getItemWithExpiration('activeVerseVersion') === 'net' && action.payload.verse_net) {
          state.activeVerseVersion = 'net';
        }
        else if (getItemWithExpiration('activeVerseVersion') === 'msg' && action.payload.verse_msg) {
          state.activeVerseVersion = 'msg';
        }
        else if (getItemWithExpiration('activeVerseVersion') === 'mt' && action.payload.verse_mt) {
          state.activeVerseVersion = 'mt';
        }
        else if (getItemWithExpiration('activeVerseVersion') === 'tr' && action.payload.verse_tr) {
          state.activeVerseVersion = 'tr';
        }

      }

    },
    setActiveVerseVersion: (state, action) => {
      state.activeVerseVersion = action.payload;
      setItemWithExpiration('activeVerseVersion', action.payload, 1000 * 60 * 60 * 24 * 365);
    },
    setIsUserSearching: (state, action) => {
      state.isUserSearching = action.payload;
    },
    setIsMenuOpen: (state, action) => {
      state.isMenuOpen = action.payload;
    },
    setIsCrossPopupOpen: (state, action) => {
      state.isCrossPopupOpen = action.payload;
    },
    setViewMode: (state, action) => {
      state.viewMode = action.payload;
      setItemWithExpiration('viewMode', action.payload, 1000 * 60 * 60 * 24 * 365);
    },
    setParticles: (state, action) => {
      state.settings.particles = action.payload;
      setItemWithExpiration('particles', action.payload, 1000 * 60 * 60 * 24 * 365);
    },
    setHighlight: (state, action) => {
      state.settings.highlight = action.payload;
      setItemWithExpiration('highlight', action.payload, 1000 * 60 * 60 * 24 * 365);
    },
    setCrossPopupSize: (state, action) => {
      state.settings.crossPopupSize = action.payload;
      setItemWithExpiration('crossPopupSize', action.payload, 1000 * 60 * 60 * 24 * 365);
    },
    setPresentMode: (state, action) => {
      state.settings.present_mode = action.payload;
      setItemWithExpiration('present_mode', action.payload, 1000 * 60 * 60 * 24 * 365);
    },
    setMusic: (state, action) => {
      state.settings.music = action.payload;
      setItemWithExpiration('music', action.payload, 1000 * 60 * 60 * 24 * 365);
    },
    setRepeat: (state, action) => {
      state.settings.repeat = action.payload;
      setItemWithExpiration('repeat', action.payload, 1000 * 60 * 60 * 24 * 365);
    },
    setNotes: (state, action) => {
      state.settings.notes = action.payload;
      setItemWithExpiration('notes', action.payload, 1000 * 60 * 60 * 24 * 365);
    },
    setActiveCategory: (state, action) => {
      state.activeCategory = action.payload;

      if (action.payload === null) {
        setItemWithExpiration('activeCategory', null, 1000 * 60 * 60 * 24 * 365);
      }

    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.fulfilled, (state, action) => {

        if (action.payload === null) {
          state.categories = [];
        }
        else {
          state.categories = action.payload;
        }

      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.categories = [];
      })
      .addCase(fetchBooks.fulfilled, (state, action) => {

        if (action.payload === null) {
          state.canon_list = [];
        }
        else {
          state.canon_list = action.payload;
        }

      })
      .addCase(fetchBooks.rejected, (state, action) => {
        state.canon_list = [];
      })
      .addCase(getCategory.fulfilled, (state, action) => {

        if (action.payload === null) {
          state.activeCategory = null;
        }
        else {
          setItemWithExpiration('activeCategory', action.payload._id, 1000 * 60 * 60 * 24 * 365);
          state.activeCategory = action.payload;
        }

      })
      .addCase(getCategory.rejected, (state, action) => {
        state.activeCategory = null;
      })
  }
});

export const { setIsMenuOpen, setIsUserSearching, setActiveCategory, setActiveVerseVersion, setActiveVerse, setViewMode, setParticles, setHighlight, setPresentMode, setMusic, setRepeat, setNotes, setIsCrossPopupOpen, setCrossPopupSize } = settingsSlice.actions;

export default settingsSlice.reducer;