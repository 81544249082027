import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setActiveVerseVersion } from '../../../../app/features/settingsSlice';

import styles from './Version.module.css';

function Version({ children, className, instantHide, ...props }) {
    const dispatch = useDispatch();

    const verse = useSelector(state => state.settings.activeVerse);
    const activeVerseVersion = useSelector(state => state.settings.activeVerseVersion);
    const isUserSearching = useSelector(state => state.settings.isUserSearching);
    const isMenuOpen = useSelector((state) => state.settings.isMenuOpen);
    
    // eslint-disable-next-line
    const versions = [];

    if (verse.verse_niv) {
        versions.push('niv');
    }
    if (verse.verse_esv) {
        versions.push('esv');
    }
    if (verse.verse_nasb) {
        versions.push('nasb');
    }
    if (verse.verse_nkjv) {
        versions.push('nkjv');
    }
    if (verse.verse_lsb) {
        versions.push('lsb');
    }
    if (verse.verse_nlt) {
        versions.push('nlt');
    }
    if (verse.verse_csb) {
        versions.push('csb');
    }
    if (verse.verse_amp) {
        versions.push('amp');
    }
    if (verse.verse_hcsb) {
        versions.push('hcsb');
    }
    if (verse.verse_gnt) {
        versions.push('gnt');
    }
    if (verse.verse_ceb) {
        versions.push('ceb');
    }
    if (verse.verse_rsv) {
        versions.push('rsv');
    }
    if (verse.verse_nrsv) {
        versions.push('nrsv');
    }
    if (verse.verse_net) {
        versions.push('net');
    }
    if (verse.verse_msg) {
        versions.push('msg');
    }
    if (verse.verse_mt) {
        versions.push('mt');
    }
    if (verse.verse_tr) {
        versions.push('tr');
    }

    const handleVersionChange = useCallback((version) => {
        dispatch(setActiveVerseVersion(version));
    }, [dispatch]);

    useEffect(() => {

        const handleKeydown = (event) => {
            const key = event.keyCode;
            const isModifierKey = event.metaKey || event.ctrlKey || event.altKey || event.shiftKey;

            // Ignore common shortcuts like Cmd+C, Cmd+V, etc.
            if (isModifierKey) {
                return;
            }

            // arrow up
            if (key === 38) {
                event.preventDefault();

                const activeIndex = versions.indexOf(activeVerseVersion);
                const nextIndex = activeIndex - 1;

                if (nextIndex >= 0) {
                    handleVersionChange(versions[nextIndex]);
                }

            }

            // arrow down
            if (key === 40) {
                event.preventDefault();

                const activeIndex = versions.indexOf(activeVerseVersion);
                const nextIndex = activeIndex + 1;

                if (nextIndex < versions.length) {
                    handleVersionChange(versions[nextIndex]);
                }

            }

        };

        if (!isUserSearching && !isMenuOpen) {
            window.addEventListener('keydown', handleKeydown);
        }
    
        return () => {
          window.removeEventListener('keydown', handleKeydown);
        };
    }, [isMenuOpen, isUserSearching, activeVerseVersion, versions, handleVersionChange]);

    return (
        <div className={styles.list} {...props}>
            {versions.map((version, index) => (
                <div 
                    key={index} 
                    className={`${styles.item} ${activeVerseVersion === version && styles.active}`}
                    onClick={() => handleVersionChange(version)}
                >
                    {version.toUpperCase()}
                </div>
            ))}
        </div>
    );
}
    
export default Version;